import { graphql } from "gatsby";
import * as React from "react";
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Layout from "~/components/Layout";
import { ArticleRenderer } from '../components/ArticleRenderer';
import { CaseStudyMasthead } from '../components/flexible/mastheads/CaseStudyMasthead';
import { Button } from '../components/elements/buttons/Button';
import Image from '~/components/elements/Image';
import { Link } from "gatsby";


export default function caseStudyPage ({
    data: { wpCaseStudy, wpPage, wp },
  }

 
){
  // console.log(wpCaseStudy)
  return (
    <Layout wp={wp}>
      <Seo post={wpCaseStudy} />
      <CaseStudyMasthead {...wpCaseStudy?.postTypeCaseStudy?.caseStudySummary}/>
      <section className="section-contained xl:!w-[1360px] case-study-details font-body section-top section-bot bg-white rounded-3xl p-6 lg:py-26 lg:px-20">
        <div className="flex flex-col lg:flex-row gap-y-10 gap-x-8 lg:gap-36 relative">
          <div className="lg:max-w-[260px]">
            <div className="sticky top-[4.5rem]">
              <ul className="grid grid-cols-2 lg:block">
                <li className="mb-8">
                  <h3 className="uppercase t-12-bold mb-2">Company</h3>
                  <span>{wpCaseStudy?.postTypeCaseStudy?.caseStudySummary?.company}</span>
                </li>
                <li className="mb-8">
                  <h3 className="uppercase t-12-bold mb-2">Industry</h3>
                  <span>{wpCaseStudy?.postTypeCaseStudy?.industry}</span>
                </li>
                <li className="mb-8">
                  <h3 className="uppercase t-12-bold mb-2">Company size</h3>
                  <span>{wpCaseStudy?.postTypeCaseStudy?.companySize}</span>
                </li>
                <li className="mb-8 col-span-2">
                  <h3 className="uppercase t-12-bold mb-4">Key pain points</h3>
                  <div className="bullet-points"
                       dangerouslySetInnerHTML={{__html: wpCaseStudy?.postTypeCaseStudy?.keyPoints}}/>
                </li>
              </ul>
              <Button type="button" color="coral" link={wpCaseStudy?.postTypeCaseStudy?.ctaLink}/>
            </div>
          </div>
          <article className="flex-auto">
            <ArticleRenderer
              prefix="CaseStudy_Posttypecasestudy_ArticleContent_"
              content={wpCaseStudy?.postTypeCaseStudy?.articleContent}
            />
          </article>
        </div>
      </section>
      <ul className="container !max-w-[1200px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 pt-12 pb-7">
        {wpCaseStudy?.postTypeCaseStudy?.recommended?.map((post,i) => {
          return (
              <RecommendedPost
                key={i}
                post={post.recommendedItem}/>
          )
        })
        }
      </ul>
    </Layout>
  )
}

const RecommendedPost = ({
  post,
}) => {

  return (
    <li className="bg-white rounded-[2rem] border-[1px] border-black/10">
        <Link to={post?.uri} className="block p-6 md:p-8 lg:p-[40px] hover:opacity-50 transition-all duration-200">
          <div className='mb-10'>
            {
              post?.featuredImage && (
                <Image className="aspect-[302/214] rounded-[20px]"
                        objectFit="cover" objectPosition="center"
                        image={post?.featuredImage?.node}>
                </Image>
              )
            }
            {
              !post?.featuredImage && (
                <div className="aspect-[302/214] rounded-[20px] bg-paleBlue"/>
              )
            }
          </div>
          <div className="max-w-[75px] h-[1.5rem] mb-6">
            <Image className="w-full h-full object-contain object-center" image={post?.postTypeCaseStudy?.caseStudySummary?.logo} />
          </div>
          <h3 className="t-heading-30 mb-8">{post?.title}</h3>
          <span className="text-coral t-19">Read story</span>
        </Link>
    </li>
  )
}

export const pageQuery = graphql`
  query CaseStudy($id: String!) {
    wpCaseStudy(id: { eq: $id }) {
      ...SEOCaseStudy
      
      ...GeneratedWpCaseStudy
    }
    wp {
      ...GeneratedWp
      header {
        optionsHeader {
          menu {
            link {
              url
              title
            }
            showSubmenu
            submenu {
              type
              withPostMenu {
                leftColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                    description
                  }
                }
              }
              withImageMenu {
                leftColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                  }
                }
                rightColumn {
                  heading
                  links {
                    link {
                      url
                      title
                    }
                  }
                }
                image {
                  ...Image
                }
              }
            }
          }
        }
      }
    }
  }
`;
